<template>
  <div class="verified-personal">
    <div class="from-box">
      <p class="title">实名认证</p>
      <p class="text">个人证件信息仅用于实名认证，不会泄漏您的任何证件信息。</p>

      <van-form validate-first class="from" ref="ruleForm">
        <van-field
          required
          input-align="right"
          error-message-align="right"
          v-model="from.realName"
          label="姓名"
          name="realName"
          placeholder="请填写姓名"
          maxlength="10"
          :rules="rules.realName"
        />

        <van-field
          required
          input-align="right"
          error-message-align="right"
          v-model="from.citizenIdNo"
          label="证件号码"
          name="citizenIdNo"
          placeholder="请填写证件号码"
          maxlength="18"
          :rules="rules.citizenIdNo"
        />

        <van-field
          required
          readonly
          clickable
          input-align="right"
          error-message-align="right"
          v-model="from.citizenIdExpired"
          label="证件有效期"
          name="citizenIdExpired"
          placeholder="请填写"
          :rules="rules.citizenIdExpired"
          @click="showPicker = true"
        >
        </van-field>

        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择证件有效期"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <div class="img-from">
          <van-field
            required
            input-align="right"
            error-message-align="right"
            size="large"
            label="请上传实名认证附件"
            readonly
            label-width="200"
            :border="false"
            class="file-text"
          />

          <p class="directions">
            图片格式要求jpg、jpeg、bmp、png，不超过3M；所有上传照片需清晰且未遮挡，上传证件信息需在证件有效期内。
          </p>

          <van-field
            name="citizenIdPhotoFrontAttmId"
            :rules="rules.citizenIdPhotoFrontAttmId"
            class="uploader"
          >
            <template #input>
              <verified-upload
                v-model="from.citizenIdPhotoFrontAttmId"
                :bg="imgList.img1"
                :accept="accept"
                :maxsize="3"
                fileTypeCode="1002"
              ></verified-upload>
            </template>
          </van-field>

          <p class="img-title">证件人像面</p>

          <van-field
            name="citizenIdPhotoBackAttmId"
            :rules="rules.citizenIdPhotoBackAttmId"
            class="uploader"
          >
            <template #input>
              <verified-upload
                v-model="from.citizenIdPhotoBackAttmId"
                :bg="imgList.img2"
                :accept="accept"
                :maxsize="3"
                fileTypeCode="1003"
              ></verified-upload>
            </template>
          </van-field>

          <p class="img-title">证件国徽面</p>

          <van-field
            name="citizenIdPhotoHoldAttmId"
            :rules="rules.citizenIdPhotoHoldAttmId"
            class="uploader"
          >
            <template #input>
              <verified-upload
                v-model="from.citizenIdPhotoHoldAttmId"
                :bg="imgList.img3"
                :accept="accept"
                :maxsize="3"
                fileTypeCode="1004"
              ></verified-upload>
            </template>
          </van-field>

          <p class="img-title-last">手持证件人像面照片</p>
        </div>
      </van-form>
    </div>
    <div class="btn">
      <van-button class="btn-right" color="#F5DEDD" @click="cancel"
        >取消</van-button
      >
      <van-button class="btn-left" color="#EA0B06" @click="handleSubmit"
        >提交认证</van-button
      >
    </div>
  </div>
</template>

<script>
import verified_upload from "@/page/verified/components/verified_upload";
import { mapActions, mapGetters } from "vuex";
import { applyUserCertification } from "@/api/res-user";
export default {
  name: "verified_personal",
  components: {
    "verified-upload": verified_upload,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      accept: ".jpg,.jpeg,.bmp,.png",
      showPicker: false,
      currentDate: new Date(),
      minDate: new Date(1949, 10, 1),
      maxDate: new Date(2500, 10, 1),
      imgList: {
        img1: require("@/assets/img/verified_per1.png"),
        img2: require("@/assets/img/verified_per2.png"),
        img3: require("@/assets/img/verified_per3.png"),
      },
      from: {
        realName: "",
        citizenIdNo: "",
        citizenIdExpired: "",
        citizenIdPhotoFrontAttmId: "",
        citizenIdPhotoBackAttmId: "",
        citizenIdPhotoHoldAttmId: "",
      },
      rules: {
        citizenIdExpired: [
          { required: true, message: "请输入证件有效期", trigger: "onChange" },
        ],
        realName: [{ required: true, trigger: "onChange" }],
        citizenIdNo: [
          { required: true, trigger: "onBlur" },
          {
            pattern:
              /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: "证件号码格式错误！",
            trigger: "onBlur",
          },
        ],
        citizenIdPhotoFrontAttmId: [
          { required: true, message: "请上传证件人像面", trigger: "onChange" },
        ],
        citizenIdPhotoBackAttmId: [
          { required: true, message: "请上传证件国徽面", trigger: "onChange" },
        ],
        citizenIdPhotoHoldAttmId: [
          {
            required: true,
            message: "请上传手持证件人像面照片",
            trigger: "onChange",
          },
        ],
      },
    };
  },

  mounted() {
    document.title = "实名认证";

    const { userInfo, from } = this;
    console.log(userInfo, "userInfo");
    if (userInfo.verifyStatus !== "1") {
      Object.keys(from).forEach((k) => {
        this.from[k] = userInfo[k];
      });
    }
  },

  methods: {
    ...mapActions(["GetInfo"]),
    onConfirm(time) {
      let citizenDate = new Date(time);
      let year = citizenDate.getFullYear();
      let month = citizenDate.getMonth() + 1;
      let date = citizenDate.getDate();
      if (month < 10) month = `0${month}`;
      if (date < 10) date = `0${date}`;

      this.from.citizenIdExpired = `${year}-${month}-${date}`;
      this.showPicker = false;
    },
    async handleSubmit() {
      let this_ = this
      await this.GetInfo();
      this.$refs.ruleForm.validate().then(() => {
          this_.$toast.loading({
            duration: 0,
            message: "加载中...",
            forbidClick: true,
          });
          applyUserCertification(this.from).then(() => {
            this.GetInfo();
            this_.$toast.clear();
            this_.$toast.success('提交成功！');
            this_.$router.push("/verified");
          });
        })
        .catch(() => {
          return false;
        });
    },
    cancel() {
      this.$router.go(-2);
    },
  },
};
</script>

<style scoped lang="scss">
%text_xing {
  content: "* ";
  color: #ea0b06;
}

.verified-personal {
  width: 100vw;
  height: 100%;
  background: #f6f6f6;
}

.from-box {
  width: 100vw;
  height: 100%;
  background: #f6f6f6;
  padding: 16px;
  > .title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  > .text {
    font-size: 12px;
    //font-weight: 500;
    color: #999999;
    margin: 5px 0 16px 0;
  }
}

.btn {
  width: 100vw;
  height: 92px;
  /* position: fixed;
   bottom: 0;
   left: 0;*/
  padding: 0 16px 48px 16px;
  background: #f6f6f6;
  > .btn-right::v-deep {
    color: #ea0b06 !important;
    width: 96px;
    height: 44px;
  }
  > .btn-right {
    margin-right: 9px;
  }
  > .btn-left::v-deep {
    width: 238px;
    height: 44px;
  }
}

.from {
  ::v-deep {
    .van-cell__title {
      font-size: 16px;
      //font-weight: 500;
      color: #333333;
    }
    .van-cell__value {
      font-size: 16px;
      //font-weight: 500;
      color: #999999;
    }
  }

  > .img-from {
    margin-top: 12px;
    padding: 6px 0 18px;
    background: #ffffff;
    border-radius: 4px;
    &::v-deep {
      .van-cell::after {
        content: none;
      }
    }
    > .img-name {
      font-size: 16px;
      //font-weight: 500;
      color: #333333;
      /* &:before{
        @extend %text_xing
      }*/
    }
    > .directions {
      font-size: 14px;
      //font-weight: 500;
      color: #333333;
      margin: 0 8px;
    }
    %title {
      text-align: center;
      font-size: 14px;
      //font-weight: 500;
      color: #333333;
    }
    > .img-title {
      @extend %title;
      margin-bottom: 16px;
    }
    > .img-title-last {
      @extend %title;
    }
    > .uploader {
      ::v-deep {
        .van-uploader__wrapper {
          max-width: 263px;
          max-height: 155px;
        }
        .van-uploader__preview {
          margin: 0;
        }
        .van-uploader__preview-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.verified-upload {
  &::v-deep {
    img {
      width: 263px;
      height: 155px;
    }
    .van-uploader__file {
      width: 263px;
      height: 155px;
    }
  }
}
</style>
